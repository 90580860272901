@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

body {
  color: #001e4b;
}

div.btn-primary {
  border: #2f42fc;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #001e4b;
  font-weight: 700;
}

section#qik-products {
  padding: 100px 0;
  padding-bottom: 0;
}

section#contact-us {
  padding: 100px 0;
}

#header {
  background: #fff;
  height: 94px;
  padding-top: 8px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 99;
}

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.uvstable tr td {
  border: 0;
  vertical-align: top;
}

.uvstable tr td p {
  font-size: 14px;
}

.uvstable tr td h4 {
  font-size: 20px;
  font-weight: 700;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu div {
  display: block;
  position: relative;
  color: #001e4b;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 16px;
}

.nav-menu div:hover,
.nav-menu .active>div,
.nav-menu li:hover>div {
  color: #2f42fc;
  text-decoration: none;
}

.nav-menu div.btn.btn-primary {
  background: #2f42fc;
  color: #fff;
  border-radius: 50px;
  margin: 0 15px;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 25px;
  border-radius: 0;
}

.nav-menu div.btn.btn-primary:hover {
  background: #007bff;
  color: #fff;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
}

.nav-menu .drop-down:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul div {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #3c1300;
}

.nav-menu .drop-down ul div:hover,
.nav-menu .drop-down ul .active>div,
.nav-menu .drop-down ul li:hover>div {
  color: #2f42fc;
}

.nav-menu .drop-down>div:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down>div {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down>div:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }

  .nav-menu .drop-down .drop-down:hover>ul {
    left: -100%;
  }

  .nav-menu .drop-down .drop-down>div:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -260px;
  width: 260px;
  padding-top: 18px;
  background: #fff;
  transition: 0.4s;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #000;
  padding: 15px 30px;
  font-weight: 500;
  margin-left: 10px;
  transition: 0.3s;
}

.mobile-nav div:hover,
.mobile-nav .active>div,
.mobile-nav li:hover>div {
  color: #2f42fc;
  text-decoration: none;
}

.mobile-nav .get-started div {
  background: #2f42fc;
  color: #fff;
  border-radius: 50px;
  margin: 15px;
  padding: 10px 25px;
}

.mobile-nav .get-started div:hover {
  background: #2f42fc;
  color: #fff;
}

.mobile-nav .drop-down>div:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down>div:after {
  content: "\eaa0";
}

.mobile-nav .drop-down>div {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-toggle {
  position: fixed;
  left: 115px;
  top: 22px;
  z-index: 999998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #7a6960;
}

a.btn-primary {
  margin-bottom: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(78, 64, 57, 0.9);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

h5 {
  font-size: 20px;
}

#footer {
  margin: 50px 0;
}

.footer-top {
  border-top: 1px solid #eee;
  padding: 50px 0;
}

#qik-intro {
  padding-top: 150px;
}

#qik-products {
  padding: 100px 0;
  padding-bottom: 0;
}

#products {
  text-align: center;
}

#products h4 {
  text-align: center;
  font-size: 20px;
}

#products img {
  text-align: center;
  margin-bottom: 10px;
}

.uvs-boxes p {
  font-size: 14px;
  min-height: 60px;
}

.uvs-boxes h4 {
  text-align: center;
  font-size: 20px;
  min-height: 50px;
  margin-top: 10px;
}

.uvs-boxes .col-md-3 {
  padding: 30px 20px;
}

#qik-products {
  padding: 100px 0;
}

#qik-pricing {
  padding: 100px 0;
}

#productss img {
  width: 90px;
  height: 90px;
}

#productss {
  text-align: center;
}

.btn-custom-vs {
  background: #dff5ff;
  border-radius: 0;
  color: #2f42fc;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 30px;
  margin: 20px 0;
}

.btn-custom-vs:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.introtext {
  padding-top: 7%;
}

p {
  color: #001e4b;
  font-size: 18px;
}

.vline-span {
  position: absolute;
  top: 50%;
  margin-left: -26px;
  display: inline-block;
  white-space: nowrap;
  color: #001e4b;
  font-size: 12px;
  -moz-transform: rotate(-90deg);
  -moz-transform-origin: center center;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: center center;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: center center;
}

.uimgvs {
  padding-left: 8%;
  padding-top: 80px;
}

.uvs-classics {
  background: #fff;
  color: #001e4b;
  border: 2px solid #eee;
  margin-bottom: 20px;
}

.uvs-classics td.select-td {
  background: url('./images/plan-v.svg');
  background-size: cover;
  text-align: center;
  padding: 70px 10px;
  padding-bottom: 20px;
}

.uvs-classics-vs {
  color: #fff;
  margin-bottom: 20px;
  border: 2px solid #e6f7fe;
  margin-top: -50px;
}

.uvs-classics-vs td.select-td {
  background: url('./images/plan-vs.png');
  background-size: cover;
  text-align: center;
  padding: 70px 10px;
  padding-bottom: 40px;
}


.uvs-classics-vs .uvs-table {
  background: #2f42fc;
}

.uvs-classics-vs .uvs-table caption {
  background: #2f42fc;
}

.uvs-table {
  width: 100%;
}

.uvs-table tr td {
  border: 0;
  padding: 10px;
  font-size: 14px;
  padding: 10px 20px;
}

.uvs-table caption {
  font-size: 18px;
  caption-side: top;
  padding-left: 80px;
}

.btn-uvs {
  background: #fff;
  border-radius: 0;
  color: #2f42fc;
  font-weight: 500;
}

.uvs-table tr td i.fa.fa-check {
  margin-right: 10px;
}

.uvs-table td h2 {
  text-align: left;
}

div {
  cursor: pointer;
}

div:hover {
  text-decoration: none;
}

a {
  color: #2f42fc;
}

.uimgvs img {
  cursor: pointer;
  opacity: 0.5;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

img.uimgvsimg {
  cursor: pointer;
  opacity: 0.5;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

#productss img {
  margin-bottom: 15px;
}

img.uimgvshover {
  cursor: pointer;
  opacity: 1.0;
  -webkit-filter: unset;
  filter: unset;

}

img.uimgvshove {
  cursor: pointer;
  opacity: 1.0;
  -webkit-filter: unset;
  filter: unset;

}

.btn-primary {
  background: #2f42fc;
  border-color: #2f42fc;
  border-radius: 0;
}

.containervs {
  width: 90%;
  margin: 0 auto;
}

span.uvs-linevs {
  width: 60px;
  background: #eee;
  height: 2px;
  float: left;
}

span.uvs-lines {
  width: 60px;
  background: #444;
  height: 2px;
  float: left;
}

.p-2 {
  margin: 10px 20px;
}

.products-1vs {
  padding: 50px 30px;
  border: 1px solid #001e4b6b;
  border-radius: 10px;
  text-align: center;
}

.heading-h1 {
  font-size: 58px;
}

#footer p {
  font-size: 18px;
}

.slider__item {
  transition: all 0.2s ease;
}

#slide-1:checked~.slider__holder .slider__item--1 {
  position: relative;
  z-index: 2;
  transform: translate(0) scale(1);
}

#slide-2:checked~.slider__holder .slider__item--1 {
  z-index: 1;
  transform: translateX(-87%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-2:checked~.slider__holder .slider__item--1 {
    opacity: 0.6;
  }
}

#slide-3:checked~.slider__holder .slider__item--1 {
  z-index: 0;
  transform: translateX(-140%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-3:checked~.slider__holder .slider__item--1 {
    transform: translateX(-17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-3:checked~.slider__holder .slider__item--1 {
    opacity: 0;
  }
}

#slide-4:checked~.slider__holder .slider__item--1 {
  z-index: -1;
  opacity: 0;
  transform: translateX(-21%) scale(0.65);
}

#slide-5:checked~.slider__holder .slider__item--1 {
  z-index: -1;
  opacity: 0;
  transform: translateX(-21%) scale(0.65);
}

#slide-1:checked~.slider__holder .slider__item--2 {
  z-index: 1;
  transform: translateX(-76%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-1:checked~.slider__holder .slider__item--2 {
    opacity: 0.6;
  }
}

#slide-2:checked~.slider__holder .slider__item--2 {
  position: relative;
  z-index: 2;
  transform: translate(0) scale(1);
}

#slide-3:checked~.slider__holder .slider__item--2 {
  z-index: 1;
  transform: translateX(-76%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-3:checked~.slider__holder .slider__item--2 {
    opacity: 0.6;
  }
}

#slide-4:checked~.slider__holder .slider__item--2 {
  z-index: 0;
  transform: translateX(-145%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-4:checked~.slider__holder .slider__item--2 {
    transform: translateX(-17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-4:checked~.slider__holder .slider__item--2 {
    opacity: 0;
  }
}

#slide-5:checked~.slider__holder .slider__item--2 {
  z-index: -1;
  opacity: 0;
  transform: translateX(-21%) scale(0.65);
}

#slide-1:checked~.slider__holder .slider__item--3 {
  z-index: 0;
  transform: translateX(81%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-1:checked~.slider__holder .slider__item--3 {
    transform: translateX(17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-1:checked~.slider__holder .slider__item--3 {
    opacity: 0;
  }
}

#slide-2:checked~.slider__holder .slider__item--3 {
  z-index: 1;
  transform: translateX(90%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-2:checked~.slider__holder .slider__item--3 {
    opacity: 0.6;
  }
}

#slide-3:checked~.slider__holder .slider__item--3 {
  position: relative;
  z-index: 2;
  transform: translate(0) scale(1);
}

#slide-4:checked~.slider__holder .slider__item--3 {
  z-index: 1;
  transform: translateX(-78%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-4:checked~.slider__holder .slider__item--3 {
    opacity: 0.6;
  }
}

#slide-5:checked~.slider__holder .slider__item--3 {
  z-index: 0;
  transform: translateX(-21%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-5:checked~.slider__holder .slider__item--3 {
    transform: translateX(-17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-5:checked~.slider__holder .slider__item--3 {
    opacity: 0;
  }
}

#slide-1:checked~.slider__holder .slider__item--4 {
  z-index: -1;
  opacity: 0;
  transform: translateX(21%) scale(0.65);
}

#slide-2:checked~.slider__holder .slider__item--4 {
  z-index: 0;
  transform: translateX(162%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-2:checked~.slider__holder .slider__item--4 {
    transform: translateX(17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-2:checked~.slider__holder .slider__item--4 {
    opacity: 0;
  }
}

#slide-3:checked~.slider__holder .slider__item--4 {
  z-index: 1;
  transform: translateX(87%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-3:checked~.slider__holder .slider__item--4 {
    opacity: 0.6;
  }
}

#slide-4:checked~.slider__holder .slider__item--4 {
  position: relative;
  z-index: 2;
  transform: translate(0) scale(1);
}

#slide-5:checked~.slider__holder .slider__item--4 {
  z-index: 1;
  transform: translateX(-10%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-5:checked~.slider__holder .slider__item--4 {
    opacity: 0.6;
  }
}

#slide-1:checked~.slider__holder .slider__item--5 {
  z-index: -1;
  opacity: 0;
  transform: translateX(21%) scale(0.65);
}

#slide-2:checked~.slider__holder .slider__item--5 {
  z-index: -1;
  opacity: 0;
  transform: translateX(21%) scale(0.65);
}

#slide-3:checked~.slider__holder .slider__item--5 {
  z-index: 0;
  transform: translateX(21%) scale(0.65);
}

@media (max-width: 900px) {
  #slide-3:checked~.slider__holder .slider__item--5 {
    transform: translateX(17%) scale(0.65);
  }
}

@media (max-width: 768px) {
  #slide-3:checked~.slider__holder .slider__item--5 {
    opacity: 0;
  }
}

#slide-4:checked~.slider__holder .slider__item--5 {
  z-index: 1;
  transform: translateX(10%) scale(0.85);
}

@media (max-width: 768px) {
  #slide-4:checked~.slider__holder .slider__item--5 {
    opacity: 0.6;
  }
}

#slide-5:checked~.slider__holder .slider__item--5 {
  position: relative;
  z-index: 2;
  transform: translate(0) scale(1);
}

.slider {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider__radio {
  display: none;
}

.slider__holder {
  position: relative;
  width: 100vw;
  max-width: 24.3%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: left;
}

@media (max-width: 900px) {
  .slider__holder {
    max-width: 90vw;
  }
}

@media (max-width: 600px) {
  .slider__holder {
    margin-top: 60px;
  }
}

.slider__item {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  opacity: 1;
  cursor: pointer;
}

.slider__item-content {
  padding: 0px;
}

@media (max-width: 600px) {
  .slider__item-content {
    padding: 0;
  }
}

@media (max-width: 375px) {
  .slider__item-content {
    padding: 0;
  }
}

.slider__item-text {
  padding: 60px 0;
}

.scrollsvs {
  position: absolute;
  bottom: -20%;
  left: 12%;
  height: 300px;
  width: 1.5px;
  background: #001e4b
}

#footer li {
  font-size: 18px;
}

#footer .copyright p {
  font-size: 14px;
  color: #001e4b;
  font-weight: 600;
}


.hovuvsr {
  opacity: 1;
  webkit-filter: grayscale(0);
  filter: grayscale(0);
}

h4 {
  font-size: 26px;
  color: #001e4b;
}

h2 {
  font-size: 40px;
}

.btn-primary {
  font-size: 16px;
}

.big-font {
  font-size: 41px;
}

.btn-uvs-custom {
  margin-top: 70px;
}

#productsvs {
  padding-top: 50px;
}

.cta-uvs-btn {
  font-weight: 600;
  padding: 10px 22px;
  font-size: 18px;
}

.navbar-light,
.bg-light {
  background-color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #001e4b;
  padding: 10px 25px;
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #2f42fc;
}

@media (max-width:780px) {
  div#navbarNav {
    width: 300px;
    background-color: #eee !important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #001e4b;
    padding: 13px 25px;
    font-size: 16px;
    width: 280px;
    border-bottom: 1px solid #eee;
    background: #fff !important;
  }

  img.img-logovs {
    width: 100%;
    margin-top: 15px;
  }

  #qik-intro {
    padding-top: 100px;
  }

  .uvs-classics-vs {
    margin-top: 0px;
  }

  p {
    color: #001e4b;
    font-size: 14px;
    line-height: 25px;
  }

  #productsvs {
    padding-top: 10px;
  }

  .btn-custom-vs {
    background: #dff5ff;
    border-radius: 0;
    color: #2f42fc;
    font-weight: 600;
    font-size: 16px;
    padding: 5px 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .btn-uvs-custom {
    margin-top: 20px;
  }

  .cta-uvs-btn {
    font-weight: 600;
    padding: 5px 12px;
    font-size: 16px;
  }

  #slide-1:checked~.slider__holder .slider__item--1 {
    position: relative;
    z-index: 2;
    transform: translate(10%) scale(1);
  }

  #slide-4:checked~.slider__holder .slider__item--4 {
    position: relative;
    z-index: 2;
    transform: translate(10%) scale(1);
  }

  #slide-3:checked~.slider__holder .slider__item--3 {
    position: relative;
    z-index: 2;
    transform: translate(10%) scale(1);
  }

  #slide-2:checked~.slider__holder .slider__item--2 {
    position: relative;
    z-index: 2;
    transform: translate(10%) scale(1);
  }


  .m-hide {
    display: none;
  }

  .introtext {
    padding-left: 0%;
    padding-top: 20px;
    text-align: center;
  }

  .heading-h1 {
    font-size: 36px;
    text-align: center;
  }

  h2 {
    font-size: 26px;
  }

  .uimgvs {
    padding-top: 0;
    padding-left: 0;
  }

  #products img {
    width: 90px;
    height: 90px;
    text-align: center;
    margin-bottom: 10px;
  }

  .products-1vs {
    padding: 10px 0px;
  }

  #products .row h5 {
    font-size: 16px;
  }

  #products a {
    font-size: 12px;
  }

  a#showvs-edu,
  a#showvs {
    margin-left: 20px;
  }

  #footer h4 {
    font-size: 16px;
  }


  #productss h5 {
    font-size: 16px;
  }

  #productss a {
    font-size: 12px;
    padding-bottom: 20px;
    float: left;
  }

  .slider__holder {
    margin-top: 20px;
    overflow: auto;
  }

  .mobile-nav ul {
    padding: 0 20px;
  }

  a.btn-primary {
    background: #2f42fc;
    border-color: #2f42fc;
    border-radius: 0;
    text-align: left;
  }

  #footer p,
  #footer li {
    font-size: 13px;
  }


  #footer .copyright p {
    font-size: 13px;
    color: #001e4b;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #001e4b;
  }

  .containervs h2 {
    font-size: 24px;
    font-weight: 600;
  }


  .big-font {
    font-size: 30px;
  }

}

@media (max-width:480px) {
  .heading-h1 {
    font-size: 26px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  #footer .copyright p {
    font-size: 12px;
  }

  .products-1vs {
    padding: 20px;
  }

  h5 {
    font-size: 14px;
  }

  #products .row h5 {
    font-size: 14px;
  }

}

.modal-open .modal {
  z-index: 9999999999999;
}

.containervs-text-center {
  text-align: center;
}


.slider {
  height: 500px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider__nav {
  width: 12px;
  height: 12px;
  margin: 2rem 12px;
  border-radius: 50%;
  z-index: 10;
  outline: 6px solid #ccc;
  outline-offset: -6px;
  box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slider__nav:checked {
  -webkit-animation: check 0.4s linear forwards;
  animation: check 0.4s linear forwards;
}

.slider__nav:checked:nth-of-type(1)~.slider__inner {
  left: 0%;
}

.slider__nav:checked:nth-of-type(2)~.slider__inner {
  left: -100%;
}

.slider__nav:checked:nth-of-type(3)~.slider__inner {
  left: -200%;
}

.slider__nav:checked:nth-of-type(4)~.slider__inner {
  left: -300%;
}

.slider__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.slider__contents {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider__image {
  font-size: 2.7rem;
  color: #2196F3;
}

.slider__caption {
  font-weight: 500;
  margin: 2rem 0 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.slider__txt {
  color: #999;
  margin-bottom: 3rem;
  max-width: 300px;
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@media (max-width:780px) {
  .slider {
    height: 900px;
  }
}